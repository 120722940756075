.theme__primary {
    background-color: $color-secondary-900;

    .expander__target--closed::after {
        background: linear-gradient(transparent, $color-secondary-900);
    }

    .expander__button {
        background: $color-secondary-900;
    }

    &.teaser__container,
    .teaser__container {
        .teaser {
            background-color: $color-white;
        }
    }

    .job__container.show,
    .job__container.collapsing {
        background-color: $color-white;
    }

    &.team,
    &.jobs,
    &.insurance {
        .teaser__container.teaser__container-team {
            background-color: $color-white;
        }

        .teaser {
            background-color: $color-secondary-800;
        }
    }
}
