.image {
    transition: transform $animation-speed ease;
}

.image--blur-up {
    filter: blur(10px);

    transition: filter $animation-speed;

    &.lazyloaded {
        filter: blur(0);
    }
}

.image__wrapper {
    display: block;
    overflow: hidden;

    &--has-link:hover .image {
        transform: scale(1.05);
    }
}
