.subline {
    font-size: 20px;
    line-height: 29px;
}

.subline__wrapper {
    padding-bottom: 5px;
}

@include media-breakpoint-up(md) {
    .subline__wrapper {
        padding-bottom: 25px;
    }

    .subline {
        font-size: 20px;
    }
}

@include media-breakpoint-up(xl) {
    .subline__wrapper {
        padding-bottom: 35px;
    }

    .subline {
        font-size: 24px;
        line-height: 30px;
    }
}
