h1,
h2,
h3,
h4,
h5,
h6 {
    color: $color-black;
    font-family: $font-primary-stack;
}

h1,
.h1 {
    font-size: 24px;
    line-height: 34px;
}

h2,
.h2 {
    font-size: 20px;
    line-height: 29px;
}

@include media-breakpoint-up(md) {
    h1,
    .h1 {
        font-size: 30px;
        line-height: 40px;
    }
}

@include media-breakpoint-up(xl) {
    h1,
    .h1 {
        font-size: 42px;
        line-height: 52px;
    }

    h2,
    .h2 {
        font-size: 24px;
        line-height: 30px;
    }
}
