footer {
    padding-bottom: 50px;
    border-top: 2px $color-primary solid;
}

.footer {
    &__img {
        padding: 20px 0 11px;

        .image__wrapper {
            width: 100%;
            max-width: 165px;
        }
    }

    &__copy {
        padding: 0 0 5px;

        color: $color-secondary-600;
        line-height: 26px;

        a {
            color: $color-secondary-600;
            text-decoration: none;
        }
    }

    &__links {
        margin-top: 20px;

        .accordion:first-of-type {
            margin-top: 0;
        }
    }

    &__logo {
        width: 25px;
        height: 25px;
    }
}

svg {
    width: 165px;
    height: 50px;
}

@include media-breakpoint-up(md) {
    .footer__img {
        padding: 27px 0 24px;
    }

    .footer__copy {
        padding: 18px 0 17px;

        text-align: left;
    }
}

@include media-breakpoint-up(lg) {
    .footer {
        padding-bottom: 0;
    }
}

@include media-breakpoint-up(xl) {
    .footer__img {
        padding: 45px 0 0;

        .image__wrapper {
            max-width: 280px;
        }
    }

    svg {
        width: 280px;
        height: 81px;
    }

    .footer__copy {
        padding: 20px 0 50px;
    }

    .footer__links {
        margin-top: 35px;
    }

    .footer {
        .accordion__headline {
            display: none;
        }

        .accordion__content {
            display: block;
        }

        /* stylelint-disable */
        .link-list {
            .link-list__item {
                font-weight: $font-weight-medium;

                color: $color-secondary-600;

                background: transparent;

                a {
                    display: inline-block;
                    padding: 6px 0;

                    color: inherit;

                    &:hover {
                        color: $color-primary;
                    }
                }

                &--active {
                    color: $color-black;

                    font-weight: $font-weight-normal;

                    a:hover {
                        color: $color-black;
                    }
                }
            }
        }
        /* stylelint-enable */
    }
}
