.search-results {
    padding: 25px 0 30px;
}

.search-results__header {
    .headline__wrapper {
        padding-bottom: 20px;
    }

    .subline__wrapper {
        padding-bottom: 25px;

        color: $color-secondary-600;
    }
}

.search-results__badge {
    min-width: 30px;

    margin-left: 5px;
    padding-right: 8px;
    padding-left: 4px;

    color: $color-primary;
    font-weight: $font-weight-medium;
    font-size: 16px;

    background-color: $color-white;

    .accordion__headline:not(.collapsed) .search-results__badge {
        color: $color-white;

        background-color: $color-primary;
    }
}

.search-results__list {
    margin-bottom: 0;
    padding: 15px 20px;

    border-radius: 3px;

    color: $color-black;

    list-style-type: none;

    background-color: $color-secondary;

    .copy {
        margin-bottom: 20px;
    }

    .headline__wrapper {
        padding-bottom: 0;
    }
}

.search-results__list-item {
    + .search-results__list-item {
        margin-top: 15px;
        padding-top: 15px;

        border-top: 2px solid $color-secondary-600;
    }
}

@include media-breakpoint-up(md) {
    .search-results {
        padding: 55px 0 40px;
    }

    .search-results__results {
        position: relative;

        flex-wrap: nowrap;

        &.row:not(.no-gutters) {
            margin-right: 0;
            margin-left: 0;

            .accordion {
                flex: none;
                width: auto;

                padding: 0;
            }
        }

        .accordion:hover .accordion__headline {
            color: $color-primary;

            &.tab__title--active {
                color: $color-black;
            }
        }

        .accordion__headline {
            margin-right: 1px;
            padding: 5px 10px 5px 15px;

            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            color: $color-black;
            font-size: 16px;

            background-color: $color-secondary-800;

            &:not(.collapsed) {
                display: inline-block;
            }

            &.tab__title--active {
                background-color: $color-secondary;
            }
        }

        .icon-minus {
            display: none;
        }

        .subline {
            font-size: 18px;
        }
    }

    .search-results__list {
        width: 100%;

        &--mobile {
            display: none;
        }

        .headline__wrapper {
            padding-bottom: 5px;
        }

        .headline {
            font-size: 24px;
        }

        .copy {
            margin-bottom: 10px;
        }
    }

    .search-results__badge {
        min-width: 24px;

        padding-right: 6px;

        color: $color-white;
        font-size: 13px;

        background-color: $color-primary;
    }
}

@include media-breakpoint-up(xl) {
    .search-results {
        padding: 90px 0 40px;
    }

    .search-results__header {
        .headline__wrapper {
            padding-bottom: 30px;
        }

        .subline__wrapper {
            padding-bottom: 35px;
        }
    }

    .search-results__results {
        .accordion__headline {
            font-size: 18px;
        }
    }

    .search-results__badge {
        font-size: 14px;
    }

    .search-results__list {
        .headline {
            font-size: 28px;
        }

        .copy {
            margin-bottom: 15px;

            line-height: 30px;

            p {
                line-height: 30px;
            }
        }
    }
}
