.service-badge {
    position: fixed;
    top: 100vh;
    right: 0;
    z-index: 45;

    display: flex;
    flex-direction: column;
    width: 100%;

    color: $color-white;

    transform: translateY(0);

    transition: $animation-speed ease transform;

    &.service-badge--open {
        transform: translateY(-100%);
    }

    hr {
        margin-top: 20px;
        border-top: 2px solid $color-secondary-550;
    }
}

.service-badge__trigger {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2000;

    display: flex;
    align-self: flex-end;
    border-radius: 5px 5px 0 0;

    color: $color-white;

    font-size: 32px;

    background: $color-primary;

    transform: translateY(-48px);

    cursor: pointer;

    transition: all $animation-speed;

    &:hover {
        color: $color-primary;

        background-color: $color-secondary-800;
        cursor: pointer;

        .service-badge__link {
            border-color: $color-primary;
        }
    }
}

.service-badge__close {
    position: absolute;
    right: 14px;

    color: $color-secondary-600;
    font-size: 30px;

    cursor: pointer;
}

.service-badge.service-badge--open .service-badge__trigger {
    color: $color-black;

    background: $color-secondary-800;

    .service-badge__link {
        border-color: $color-black;
    }
}

.service-badge__link {
    width: 60px;
    padding: 10px;

    border-right: 1px solid $color-white;

    color: inherit;

    text-align: center;
    text-decoration: none;

    &:hover {
        color: inherit;
        text-decoration: none;
    }

    &:last-child {
        width: 58px;

        border-right: 0;
    }

    i {
        display: block;

        font-size: 28px;
    }
}

.service-badge__content {
    padding: 20px 30px 30px;

    color: $color-secondary-400;

    background: $color-white;
}

.service-badge__headline {
    color: $color-secondary-600;
    font-weight: $font-weight-light;
    font-size: 24px;
}

.service-badge__copy,
.service-badge__phone,
.service-badge__mail,
.service-badge__opening {
    display: block;

    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
}

.service-badge__copy {
    margin-top: 15px;
}

.service-badge__phone {
    margin-top: 15px;
}

.service-badge__actions {
    margin-top: 15px;

    .btn__wrapper {
        display: block;
        width: 100%;

        text-align: center;

        .btn {
            width: 100%;
        }

        &:last-child {
            margin-top: 15px;
        }
    }
}

@include media-breakpoint-down(md) {
    .service-badge__wrapper {
        position: fixed;
        bottom: 0;
        z-index: 1010;

        width: 0;
        height: 0;

        background-color: transparent;

        transition: background-color $animation-speed;

        &.service-badge--open {
            width: 100%;
            height: 100%;

            background-color: fade-out($color-secondary-200, 0.6);
        }
    }
}

@include media-breakpoint-up(lg) {
    .service-badge {
        top: 193px;
        right: 0;

        flex-direction: row;
        width: auto;

        transform: translateX(100%) translateY(0);

        &.service-badge--open {
            transform: translateX(0);

            .service-badge__content {
                box-shadow: 0 0 10px 0 fade-out($color-secondary-200, 0.6);
            }
        }
    }

    .service-badge__trigger {
        top: 0;
        left: -70px;

        flex-direction: column;
        align-self: flex-start;
        width: 70px;
        border-radius: 5px 0 0 5px;

        transform: translateX(0) translateY(0);
    }

    .service-badge__link {
        width: 70px;
        border-right: 0;
        border-bottom: 2px solid $color-white;

        &:last-child {
            width: 70px;

            border-bottom: 0;
        }

        i {
            font-size: 38px;
            line-height: 52px;
        }
    }

    .service-badge__content {
        width: 530px;
        max-width: calc(100vw - 70px);
        padding: 30px;

        box-shadow: 0 0 0 transparent;

        transition: box-shadow $animation-speed;
    }

    .service-badge__actions {
        .btn__wrapper {
            width: 100%;

            text-align: left;

            .btn {
                width: auto;
            }
        }
    }
}
