.text-image-combination {
    padding: 24px 0;

    .copy {
        margin-top: 15px;
    }

    .btn__wrapper {
        margin-top: 30px;

        &,
        .btn {
            width: 100%;
        }
    }

    .text--image-combination__subline {
        margin-bottom: 10px;
    }
}

.text-image-combination__row {
    display: block;
}

@include media-breakpoint-down(sm) {
    .text-image-combination {
        &.expander__holder--padding-top {
            padding-top: 60px;
        }

        &.expander__holder--padding-bottom {
            padding-bottom: 40px;
        }

        .text-image-combination__row {
            margin-top: 15px;
        }
    }
}

@include media-breakpoint-up(md) {
    .text-image-combination {
        padding: 56px 0;

        &::after {
            display: block;
            clear: both;

            content: "";
        }

        .btn__wrapper {
            &,
            .btn {
                width: auto;
            }
        }

        .copy {
            margin-top: 0;
        }

        &--image-left {
            .text-image-combination__image-container {
                float: left;
                padding-right: $grid-gutter-width !important;
            }
        }

        &--image-right {
            .text-image-combination__image-container {
                float: right;
                padding-left: $grid-gutter-width !important;
            }
        }

        &--image-portrait {
            .text-image-combination__image-container {
                max-width: calc(#{5 / 12} * 100% + #{$grid-gutter-width / 2});
            }
        }
    }

    .text-image-combination__headline {
        margin-bottom: 20px;
    }

    .text-image-combination__subline {
        margin-bottom: 30px;
    }

    .text-image-combination__image-container {
        display: block;
        max-width: calc(#{7 / 12} * 100% + #{$grid-gutter-width / 2});
        margin: 30px 0 0;
        padding-top: 70px;
        shape-outside: inset(95px 0 0 0);
    }
}

@include media-breakpoint-up(xl) {
    .text-image-combination {
        padding: 90px 0;

        &--image-portrait {
            .text-image-combination__image-container {
                max-width: calc(#{4 / 12} * 100% + #{$grid-gutter-width / 2});
            }
        }
    }

    .text-image-combination__image-container {
        max-width: calc(#{8 / 12} * 100% + #{$grid-gutter-width / 2});
    }

    .text-image-combination__headline,
    .text-image-combination__subline {
        margin-bottom: 35px;
    }
}
