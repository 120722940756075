.error-page {
    padding-top: 20px;

    .subline__wrapper {
        padding-bottom: 20px;
    }

    .btn__wrapper,
    .btn {
        width: 100%;
    }
}

.error-page__error-code-wrapper {
    margin-top: 30px;

    text-align: center;
}

.error-page__error-code {
    display: inline-block;

    color: $color-secondary-800;
    font-size: 180px;
    line-height: 130px;
    text-decoration: none;

    &:hover {
        color: $color-secondary-800;

        text-decoration: none;
    }
}

@include media-breakpoint-up(md) {
    .error-page {
        padding-top: 55px;

        .subline__wrapper {
            padding-bottom: 25px;
        }

        .btn {
            width: auto;
        }
    }

    .error-page__error-code-wrapper {
        margin-top: 65px;
    }

    .error-page__error-code {
        font-size: 364px;
        line-height: 260px;
    }
}

@include media-breakpoint-up(xl) {
    .error-page {
        padding-top: 90px;

        .subline__wrapper {
            padding-bottom: 40px;
        }
    }

    .error-page__error-code-wrapper {
        margin-top: 50px;
    }

    .error-page__error-code {
        font-size: 512px;
        line-height: 365px;
    }
}
