.container {
    padding-right: 30px;
    padding-left: 30px;
}

.row:not(.no-gutters) {
    margin-right: -10px;
    margin-left: -10px;
}

.row:not(.no-gutters) > .col,
.row:not(.no-gutters) > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
}

@include media-breakpoint-up(md) {
    .container {
        padding: 0 50px;
    }
}

@include media-breakpoint-up(lg) {
    .container {
        padding: 0 70px;
    }
}

@include media-breakpoint-up(xl) {
    .row:not(.no-gutters) {
        margin-right: -15px;
        margin-left: -15px;
    }

    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*="col-"] {
        padding-right: 15px;
        padding-left: 15px;
    }
}
