.tab {
    display: none;

    &--active {
        display: block !important;
    }

    &--hidden-xs {
        display: none;
    }
}

@include media-breakpoint-up(md) {
    .tab {
        &--visible-md.tab--active {
            display: block !important;
        }
    }
}
