.events {
    padding: 35px 0;

    .text-combination {
        padding-bottom: 25px;
    }
}

@include media-breakpoint-up(md) {
    .events {
        padding: 60px 0;

        .text-combination {
            padding-bottom: 45px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .events {
        padding: 90px 0;

        .text-combination {
            padding-bottom: 65px;
        }
    }
}
