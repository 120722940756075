.content-main {
    margin-top: 80px;
}

.content-head {
    margin-top: 80px;
}

.content-head + .content-main {
    margin-top: 0;
}

@include media-breakpoint-up(xl) {
    .content-head {
        margin-top: 98px;
    }

    .content-main {
        margin-top: 173px;
    }
}
