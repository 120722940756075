.accordion__container {
    padding-bottom: 30px;
}

.expander__holder--active + .accordion__container {
    margin-top: 75px;
}

.accordion {
    margin-top: 1px;

    color: $color-white;
}

.accordion__headline {
    position: relative;

    padding: 17px 60px 17px 20px;

    border-radius: 3px;

    color: $color-black;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;

    text-align: left;

    background-color: $color-secondary-800;

    cursor: pointer;

    &.collapsed {
        color: $color-white;

        background-color: $color-primary;

        &:hover {
            color: $color-primary-300;

            background-color: $color-secondary-800;
        }

        i {
            color: $color-white;

            &::before {
                content: $icon-plus;
            }
        }
    }

    i {
        position: absolute;
        top: 50%;
        right: 20px;

        color: $color-black;

        font-size: 24px;

        transform: translateY(-50%);
    }

    &:hover {
        color: $color-primary;

        background-color: $color-secondary-800;

        .icon-minus {
            color: $color-primary;
        }
    }
}


.accordion__content .link-list__item {
    border-radius: 3px;
}

@include media-breakpoint-up(md) {
    .accordion__container {
        padding-bottom: 60px;
    }
}

@include media-breakpoint-up(xl) {
    .accordion__container {
        padding-bottom: 100px;
    }
}
