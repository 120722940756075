.newsletter {
    .teaser__btn {
        width: 100%;
        margin-top: 10px;
    }
}

.newsletter__mail {
    padding-top: 25px;
}

.newsletter-mail__headline {
    padding-bottom: 5px;

    color: $color-black;
}

.newsletter-mail__input {
    width: 100%;
    height: 50px;
    padding-left: 10px;
    border: 0;
    border-radius: 3px;

    color: $color-secondary-200;
    
    font-weight: 300;
    font-size: 18px;

    background-color: $color-secondary;

    outline: none;
}

@include media-breakpoint-up(md) {
    .newsletter__btn {
        padding-top: 15px;
    }

    .headline__wrapper-newsletter {
        flex-grow: 1;
    }

    .newsletter__mail {
        display: flex;
        padding-top: 45px;
    }
}

@include media-breakpoint-up(lg) {
    .newsletter__copy {
        font-size: 18px;
        line-height: 30px;
    }

    .newsletter__mail {
        padding-top: 0;
        padding-left: 30px;
    }

    .newsletter__btn {
        padding-top: 15px;
    }
}
