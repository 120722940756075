.expander__holder--active + .image-card__container {
    margin-top: 75px;
}

.image-card__teaser {
    position: relative;

    margin-bottom: 20px;

    text-align: left;
}

.image-card__img {
    width: 100%;
    margin: 0;

    .image {
        width: 100%;
    }
}

.image-card__headline {
    margin: 7px;
    padding: 0;

    color: $color-black;
    font-size: 16px;
    line-height: 20px;
}

.image-card__wrapper {
    position: absolute;
    bottom: 0;

    width: 100%;
    min-height: 0;
    border-left: 5px solid $color-primary;
    overflow: hidden;

    background-color: rgba(255, 255, 255, 0.9);

    transition: min-height $animation-speed;

    .teaser__btn {
        padding: 25px 0 0 20px;
    }

    .image-card__copy {
        padding: 0 30px 0 20px;
    }
}

.image-card__content {
    min-height: 0;
    max-height: 0;

    overflow: hidden;

    opacity: 0;

    transition: min-height $animation-speed;

    transition: opacity $animation-speed ease-in $animation-speed / 2;
}

@include media-breakpoint-up(md) {
    .image-card__container {
        margin-top: 0;
    }
}

@media screen and (min-width: 1450px) {
    // @toDo Headline 2 zeilig; Copy 3 Zeilig.
}

@include media-breakpoint-up(xl) {
    .image-card__headline {
        margin: 25px 30px 25px 20px;

        font-size: 24px;
        line-height: 30px;
    }

    .image-card__teaser:hover {
        .image-card__wrapper {
            min-height: 100%;
        }
        
        .image-card__content {
            min-height: 100%;
            max-height: 0;
            overflow: visible;

            opacity: 1;
        }

        .image-card__headline {
            margin-bottom: 5px;

            transition: margin-bottom $animation-speed;
        }
    }
}
