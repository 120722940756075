// Primär
.color-primary-300 {
    color: $color-primary-300;
}

.color-primary-400 {
    color: $color-primary-400;
}

.color-primary-500 {
    color: $color-primary-500;
}

.color-primary-800 {
    color: $color-primary-800;
}

// Sekundär
.color-secondary-100 {
    color: $color-secondary-100;
}

.color-secondary-200 {
    color: $color-secondary-200;
}

.color-secondary-300 {
    color: $color-secondary-300;
}

.color-secondary-400 {
    color: $color-secondary-400;
}

.color-secondary-600 {
    color: $color-secondary-600;
}

.color-secondary-800 {
    color: $color-secondary-800;
}

.color-secondary-900 {
    color: $color-secondary-900;
}

// Schwarz
.color-black {
    color: $color-black;
}

// Weiß
.color-white {
    color: $color-white;
}
