@import "app/abstracts/colors";
@import "app/abstracts/settings";

@import "app/vendor-extensions/bootstrap-settings";

@import "~bootstrap/scss/bootstrap";
@import "~aos/dist/aos.css";
@import "~@splidejs/splide/dist/css/splide.min.css";

@import "app/vendor-extensions/bootstrap-carousel";
@import "app/vendor-extensions/splide";
@import "app/vendor-extensions/dataprivacy-bundle";

@import "app/base/defaults";
@import "app/base/icons";
@import "app/base/colors";
@import "app/base/fonts";
@import "app/base/links";
@import "app/base/lists";
@import "app/base/paragraph";
@import "app/base/typography";
@import "app/base/headline";
@import "app/base/subline";

@import "app/components/buttons";
@import "app/components/image";
@import "app/components/image-card";
@import "app/components/copy";
@import "app/components/faqs";
@import "app/components/head-slider";
@import "app/components/expander";
@import "app/components/text-image-combination";
@import "app/components/navigation";
@import "app/components/link-icon";
@import "app/components/service-badge";
@import "app/components/sub-menu";
@import "app/components/mega-menu";
@import "app/components/accordion";
@import "app/components/link-list";
@import "app/components/yt-video";
@import "app/components/separator";
@import "app/components/news-teaser";
@import "app/components/downloads";
@import "app/components/team";
@import "app/components/lineclamp";
@import "app/components/events";
@import "app/components/controller-news";
@import "app/components/newsletter";
@import "app/components/jobs";
@import "app/components/insurances";
@import "app/components/search-layer";
@import "app/components/search-results";
@import "app/components/tab";

@import "app/layout/footer";
@import "app/layout/teaser";
@import "app/layout/grid";
@import "app/layout/text-combination";
@import "app/layout/insurance";
@import "app/layout/error-page";

@import "app/themes/primary";

@import "app/page/default";
