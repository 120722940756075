.teaser__container {
    padding: 20px 0;

    &--small-top {
        padding-top: 0;
    }

    &--small-bottom {
        padding-bottom: 0;
    }

    &.expander__holder--padding-top {
        padding-top: 60px;
    }

    &.expander__holder--padding-bottom {
        padding-bottom: 40px;
    }

    &-team {
        padding: 10px 20px;

        background-color: $color-secondary-900;
    }
}

.expander__holder--active + .teaser__container {
    margin-top: 45px;
}

.teaser {
    padding: 20px;

    background-color: $color-white;
}

.teaser__wrapper {
    padding: 10px 0;
}

.teaser__image {
    width: 100%;
    margin: 0;

    .image {
        width: 100%;
    }
}

a {
    .teaser__image {
        overflow: hidden;

        &:hover {
            .image {
                transform: scale(1.03);
            }
        }

        .image {
            transform: scale(1);

            transition: transform $animation-speed;
        }
    }
}

.subline.teaser__subline {
    margin: 0;
    padding: 0 0 15px;

    color: $color-secondary-600;

    line-height: 22px;
}

.teaser__button-extension {
    display: block;
    padding-top: 15px;

    text-align: center;

    .btn {
        width: 100%;
    }
}

.teaser__copy-extension {
    &--display-none {
        display: none;
    }

    .link-icon:last-of-type {
        padding-bottom: 0;

        .link-icon__link {
            padding-bottom: 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .subline__wrapper + .teaser-combination__teaser-row,
    .headline__wrapper + .teaser-combination__teaser-row {
        margin-top: 5px;
    }
}

@include media-breakpoint-up(md) {
    .teaser__container {
        padding: 60px 0 50px;

        &--small-top {
            padding-top: 0;
        }

        &--small-bottom {
            padding-bottom: 0;
        }

        &-team {
            padding: 10px 20px;
        }
    }

    .teaser__copy-extension {
        line-height: 27px;

        &--display-none {
            display: block;
        }
    }

    .teaser__button-extension {
        text-align: left;

        .btn {
            width: auto;
        }
    }

    .teaser__button--padding-bottom {
        padding-bottom: 50px;
    }
}

@include media-breakpoint-up(xl) {
    .teaser__wrapper {
        padding: 15px 0;
    }

    .teaser__container {
        padding: 90px 0 75px;

        &--small-top {
            padding-top: 0;
        }

        &--small-bottom {
            padding-bottom: 0;
        }

        &-team {
            padding: 15px 30px;
        }
    }

    .teaser {
        padding: 30px;
    }

    .teaser__subline {
        padding-bottom: 10px;

        + .teaser__button-extension {
            padding-top: 10px;
        }
    }

    .teaser__copy-extension {
        line-height: 30px;

        .link-icon {
            padding-bottom: 8px;

            line-height: 0;

            .link-icon__link {
                padding-bottom: 0;
            }
        }
    }

    .teaser__button-extension {
        padding-top: 25px;
    }
}
