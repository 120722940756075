.submenu {
    padding: 20px;

    overflow-x: auto;

    &--background-70 {
        background-color: fade_out($color-white, 0.3);
    }

    &--background-60 {
        background-color: fade_out($color-white, 0.4);
    }
}

.submenu__navigation-list {
    padding: 0;

    list-style: none;
}

.submenu__navigation-link {
    display: block;
    padding: 8px 0;

    border-bottom: 1px solid $color-secondary-200;

    color: inherit;
    font-weight: $font-weight-light;
    font-size: 18px;
    line-height: 30px;
    text-decoration: none;

    &:hover,
    .submenu__navigation-link--active {
        color: $color-primary;
        text-decoration: none;
    }
}

.submenu__navigation-link.submenu__navigation-link--active {
    color: $color-primary;
    text-decoration: none;
}
