.text-combination {
    .expander__target--closed {
        height: 500px !important;

        + .text-combination__button-row {
            padding-top: 15px;
        }
    }

    &--small {
        .expander__target--closed {
            height: 310px !important;
        }
    }

    .btn__wrapper {
        display: block;
    }

    .btn {
        width: 100%;
    }

    .headline__wrapper {
        padding: 0;

        + .subline__wrapper {
            padding-top: 5px;
        }

        + .text-combination__copy-row {
            margin-top: 15px;
        }
    }

    .subline__wrapper {
        padding-bottom: 0;

        + .text-combination__copy-row {
            padding-top: 15px;
        }
    }
}

.text-combination__button-row {
    line-height: 0;
}

.text-combination__sub-row {
    margin-top: 15px;

    &--top-20 {
        margin-top: 20px;
    }

    &--top-25 {
        margin-top: 25px;
    }
}

.text-combination__date {
    color: $color-secondary-600;
    font-size: 18px;
}

.text-combination__text-container + .text-combination__button-row {
    padding-top: 25px;
}

@include media-breakpoint-down(sm) {
    .text-combination {
        &.expander__holder--padding-top {
            padding-top: 60px;
        }

        &.expander__holder--padding-bottom {
            padding-bottom: 40px;
        }
    }
}

@include media-breakpoint-up(md) {
    .text-combination {
        padding: 56px 0;

        .expander__target--closed {
            height: auto !important;
        }

        .btn__wrapper {
            display: inline-block;
        }

        .headline__wrapper {
            + .subline__wrapper {
                padding-top: 15px;
            }

            + .text-combination__copy-row {
                margin-top: 25px;
            }
        }

        .subline__wrapper {
            + .text-combination__copy-row {
                padding-top: 25px;
            }
        }
    }

    .text-combination__copy {
        &--columns-2 {
            column-count: 2;
            column-gap: 30px;
        }
    }

    .text-combination__sub-row {
        margin-top: 50px;

        ~ .text-combination__sub-row {
            margin-top: 40px;
        }
    }

    .text-combination__date {
        padding-bottom: 35px;
    }
}

@include media-breakpoint-up(xl) {
    .text-combination {
        padding: 90px 0 100px;

        .headline__wrapper {
            + .subline__wrapper {
                padding-top: 25px;
            }

            + .text-combination__copy-row {
                margin-top: 35px;
            }
        }

        .subline__wrapper {
            + .text-combination__copy-row {
                padding-top: 35px;
            }
        }
    }

    .text-combination__date {
        padding-bottom: 34px;

        font-size: 20px;
    }

    .text-combination__sub-row {
        margin-top: 90px;

        ~ .text-combination__sub-row {
            margin-top: 90px;
        }
    }
}
