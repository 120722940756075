// Primär
$color-primary-300: #da0217 !default;
$color-primary-400: #db071a !default;
$color-primary-500: #db332e !default;
$color-primary-800: #f00 !default;

// Sekundär
$color-secondary-100: #2d2b32 !default;
$color-secondary-200: #575656 !default;
$color-secondary-300: #646464 !default;
$color-secondary-400: #6f6f6e !default;
$color-secondary-550: #979797 !default;
$color-secondary-600: #9d9d9d !default;
$color-secondary-800: #ebebeb !default;
$color-secondary-900: #f7f7f7 !default;

// Schwarz
$color-black: #000 !default;

// Weiß
$color-white: #fff !default;
