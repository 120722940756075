.search-layer {
    position: absolute;
    top: -100vw;
    right: 0;
    z-index: 9999;

    width: 0;
    height: 100vh;

    overflow-y: auto;

    background-color: $color-white;

    transition: right $animation-speed * 2, width $animation-speed * 2;

    &.is-active {
        top: 80px;

        width: 100%;

        transition: top $animation-speed, width $animation-speed;
    }
}

.search-layer-content {
    position: relative;
    top: 20px;
    z-index: 1;

    display: flex;
    flex-wrap: wrap;
    margin: auto;
}

.search-field-wrapper {
    width: 100%;

    overflow: hidden;

    opacity: 0;

    &.is-active {
        opacity: 1;
    }
}

.search-form {
    position: relative;

    display: flex;
    padding: 0;
}

.search-field {
    width: 100%;
    height: 50px;

    margin: 0;
    padding: 12px 15px;

    border: 0;
    border-radius: 3px;

    color: $color-secondary-200;
    font-weight: $font-weight-light;
    font-size: 18px;
    line-height: 18px;

    background-color: $color-secondary-900;
    outline: none;

    &::placeholder {
        height: 50px;

        font-size: 18px;
        line-height: 18px;
    }

    &:focus {
        border: 0;

        background-color: $color-secondary-800;
        outline: none;
    }
}

.search-btn {
    width: 50px;
    height: 50px;

    margin-left: 10px;

    border: 0;

    overflow: initial;

    i {
        font-size: 28px;

        cursor: pointer;
    }

    .btn {
        display: flex;
        align-items: center;
        width: 50px;
        height: 100%;

        padding: 0;
    }

    .btn__text {
        position: absolute;
        left: 12px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.autocomplete-suggestions,
.search-suggestions {
    width: calc(100% - 50px - 10px);
    max-height: none !important;

    overflow: hidden;

    font-size: 16px;

    transform: translateY(-0.625rem);
    opacity: 0;

    transition: max-height $animation-speed, opacity $animation-speed, transform $animation-speed, padding $animation-speed;

    &.autocomplete-suggestions,
    &.is-active {
        transform: translateY(0);
        opacity: 1;

        &:empty {
            opacity: 0;
        }
    }
}

.autocomplete-suggestion,
.search-suggestion-item {
    display: block;
    width: calc(100% - 70px);
    height: 40px;

    margin: 1px 0;
    padding: 12px 15px;

    border-radius: 3px;

    color: $color-secondary-200;
    font-size: 18px;
    line-height: 18px;

    background-color: $color-secondary-900;

    cursor: pointer;

    &:hover {
        color: $color-primary-500;
    }

    &.autocomplete-suggestion {
        width: 100%;
    }
}

@include media-breakpoint-up(md) {
    .search-layer-content {
        top: 30px;
    }
}

@include media-breakpoint-up(xl) {
    .search-layer {
        &.is-active {
            top: 98px;
        }
    }

    .search-layer-content {
        top: 45px;
    }
}
