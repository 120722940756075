.copy {
    color: $color-secondary-300;

    p,
    ul,
    ol {
        margin-bottom: 26px;

        line-height: 27px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        padding-left: 18px;
    }

    ol {
        padding-left: 20px;
    }

    li {
        margin-bottom: 7px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-up(xl) {
    .copy {
        font-size: 18px;

        p,
        ul,
        ol {
            margin-bottom: 30px;
        }
    }
}
