.expander__holder {
    position: relative;
}

.expander {
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    transform: translateY(50%);

    &::after {
        position: absolute;
        top: 0;
        bottom: 0;

        display: block;
        width: 100%;
        height: 2px;
        margin: auto;

        background-color: $color-secondary-600;

        content: "";
    }

    &--hidden {
        display: none;
    }

    .icon {
        transform: rotateZ(0);

        transition: transform $animation-speed;
    }

    &--open {
        .icon {
            transform: rotateX(180deg);
        }
    }
}

.expander__label {
    position: absolute;
    bottom: 0;

    width: 100%;

    color: $color-secondary-600;

    font-weight: $font-weight-medium;
    text-align: center;

    transform: translateY(90%);
}

.expander__button {
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;

    color: $color-secondary-600;

    font-size: 29px;

    background-color: $color-white;
}

.expander__target {
    position: relative;

    overflow: hidden;

    transition: height $animation-speed;

    &::after {
        position: absolute;
        bottom: 0;

        display: block;
        width: 100%;
        height: 0;

        background: linear-gradient(transparent, $color-white);

        transition: height $animation-speed;

        content: "";
    }

    &--closed {
        height: 100px !important;

        &::after {
            height: 85px;
        }
    }

    &--hidden::after {
        display: none;
    }
}

.expander__label-close {
    display: inline;
}

.expander__label-open {
    display: none;
}

.expander--closed {
    .expander__label-close {
        display: none;
    }

    .expander__label-open {
        display: inline;
    }
}

@include media-breakpoint-up(md) {
    .expander {
        display: none;
    }

    .expander__target {
        overflow: visible;

        &::after {
            display: none;
        }

        &--closed {
            height: auto !important;
        }
    }
}
