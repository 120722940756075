@font-face {
    font-weight: $font-weight-light;
    font-family: "Futura";
    src: url("#{$font-path}futura/Futura-Boo/font.woff2"),
        url("#{$font-path}futura/Futura-Boo/font.woff");
    font-display: block;
}

@font-face {
    font-weight: $font-weight-medium;
    font-family: "Futura";
    src: url("#{$font-path}futura/Futura-Med/font.woff2"),
        url("#{$font-path}futura/Futura-Med/font.woff");
    font-display: swap;
}

.font-primary {
    font-family: $font-primary-stack;

    &--light {
        font-weight: $font-weight-light;
    }

    &--medium {
        font-weight: $font-weight-medium;
    }
}
