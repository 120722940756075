//Fonts
$font-path: "./../../../font/" !default;

$font-primary-stack: "Futura", Sans-Serif !default;

$color-primary: $color-primary-300 !default;
$color-secondary: $color-secondary-900 !default;

$font-weight-medium: 500 !default;

$animation-speed: 0.33s !default;

$mega-menu-columns: 3;
