.job__container {
    padding: 10px 20px 20px;
}

.job__headline {
    padding-top: 20px;

    color: $color-black;

    font-size: 20px;
    line-height: 30px;
}

.job__subline {
    color: $color-secondary-300;

    font-size: 16px;
    line-height: 27px;
}

.job__date {
    padding: 10px 0;

    color: $color-secondary-300;
}

.job__copy {
    padding-bottom: 15px;

    p {
        margin: 0 0 15px;
    }

    ul {
        padding-left: 15px;

        color: $color-secondary-300;

        list-style-type: disc;

        li {
            margin: 0;
            padding-bottom: 15px;

            &::marker {
                font-size: 12px;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .job__container {
        padding: 15px 20px 20px;
    }

    .job__headline {
        padding-top: 45px;
    }

    .job__date {
        padding-top: 5px;
        padding-bottom: 15px;
    }

    .job__copy {
        padding-bottom: 20px;

        ul {
            li {
                padding-bottom: 10px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .job__container {
        padding: 25px 20px;
    }

    .job__subline {
        font-size: 18px;
        line-height: 30px;
    }

    .job__headline {
        padding-top: 30px;

        font-size: 24px;
        line-height: 30px;
    }

    .job__date {
        padding-top: 5px;
        padding-bottom: 35px;
    }

    .job__copy {
        padding-bottom: 40px;

        font-size: 18px;
        line-height: 24px;

        ul {
            font-size: 18px;
            line-height: 24px;

            li {
                padding-bottom: 10px;
            }
        }
    }
}
