.mega-menu__container {
    display: none;
    max-width: 100vw;
    height: 0;
    overflow: hidden;
}

@include media-breakpoint-up(xl) {
    .page--navigation-hidden {
        .mega-menu__container {
            top: 73px;
        }
    }

    .mega-menu__container {
        position: fixed;
        top: 173px;
        z-index: 50;

        display: block;
        width: 100%;
        height: 0;
        padding: 0;

        background-color: transparent;

        transition: top $animation-speed, height $animation-speed * 3, background-color $animation-speed * 3;

        &.js-nested-menu--active {
            height: calc(100vh - 100px);

            transition: height $animation-speed * 3;
        }
    }

    .mega-menu {
        position: absolute;

        width: calc(100% - 140px);
        height: 0;
        overflow: hidden;

        color: $color-secondary-300;

        background-color: $color-white;

        transition: height $animation-speed * 2;

        .submenu__navigation-link.js-nested-menu--active {
            opacity: 1;
        }

        .submenu:first-of-type {
            opacity: 0;

            transition: opacity $animation-speed * 2;
        }

        &.js-nested-menu--active {
            height: 650px;

            box-shadow: 0 2px 10px fade-out($color-black, 0.5);

            .submenu:first-of-type {
                opacity: 1;

                transition-delay: $animation-speed * 2;
            }
        }

        .teaser {
            right: 30px;
            bottom: 30px;
            left: 30px;

            border-left: 10px solid $color-primary;

            background-color: fade_out($color-white, 0.3);
        }

        .teaser__copy {
            margin-top: 10px;
        }

        .teaser__headline {
            color: $color-black;
        }

        .teaser__btn {
            margin-top: 20px;
        }
    }

    .mega-menu__content-container {
        position: relative;

        max-width: map-get($container-max-widths, xl);
        margin: auto;
    }

    .mega-menu__background-images {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 1 / $mega-menu-columns * 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .mega-menu__background-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        height: 650px;

        opacity: 0;

        transition: opacity $animation-speed * 2;

        &.js-nested-menu--active {
            z-index: 2;

            opacity: 1;
        }
    }

    .mega-menu__submenu-container {
        position: relative;
        z-index: 5;

        display: flex;
        height: 650px;

        .submenu {
            position: relative;
            top: 0;
            bottom: 0;

            width: 0;
            height: 650px;
            padding: 8px 0;

            overflow: auto;
            overflow-x: hidden;

            transition: width $animation-speed * 2, padding $animation-speed * 2;

            .simplebar-track.simplebar-vertical {
                top: 30px;
                bottom: 30px;
            }

            &--active,
            &.js-nested-menu--active {
                width: 1 / $mega-menu-columns * 100%;
                padding: 8px 20px;
            }
        }

        .submenu__navigation {
            width: 100%;
            min-width: 200px;
        }
    }

    .mega-menu__teaser-container {
        position: relative;
        top: 0;
        bottom: 0;

        width: 1 / $mega-menu-columns * 100%;
    }

    .mega-menu__teaser {
        position: absolute;
        right: 60px;
        bottom: 60px;
        left: 60px;
        z-index: 2;

        opacity: 0;

        transition: opacity $animation-speed * 2;

        &.js-nested-menu--active {
            z-index: 3;

            opacity: 1;
        }
    }

    .js-nested-menu--active {
        display: block;
    }
}
