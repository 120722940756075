p {
    color: $color-secondary-300;
    font-size: 16px;
    font-family: $font-primary-stack;
    line-height: 26px;
}

@include media-breakpoint-up(xl) {
    p {
        font-size: 18px;
        line-height: 30px;
    }
}
