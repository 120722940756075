.navigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    width: 100%;
    height: auto;


    &--fade-out,
    &.mobile-navigation--collapsed {
        height: 100%;

        .navigation__list-container {
            height: calc(100% - 80px);
        }
    }

    /* stylelint-disable */
    &--is-safari {
        .navigation__list-wrapper {
            .navigation__list-item--link {
                .container {
                    transform: translateY(2px);
                }
            }
        }
    }
    /* stylelint-enable */

    &:not(.navigation--is-safari) {
        .navigation__list-item--main {
            .container {
                transform: translateY(-1px);
            }
        }
    }
}

.mobile-navigation--collapsed {
    .navigation__brand-container,
    .navigation__list-container {
        transform: translateX(0%);
    }
}

.navigation__brand-container {
    position: relative;

    height: 80px;
    border-bottom: 2px solid $color-primary;

    background-color: $color-white;

    transition-duration: $animation-speed;

    .row,
    .container,
    .navigation__brand,
    .navigation__logo,
    .navigation__logo-link {
        height: 100%;
    }
}

.navigation__brand {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 20px;
    padding-bottom: 20px;
}

.navigation__icons {
    display: flex;

    gap: 20px;
    align-items: center;
}

.navigation__search,
.navigation__menu-bar {
    font-size: 38px;

    &:hover {
        cursor: pointer;
    }
}

.navigation__menu-close {
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    height: 58px;
    padding: 20px;
    border-radius: 3px;

    color: $color-white;

    font-size: 20px;

    background-color: $color-primary;

    .icon-arrow-left {
        display: none;
        width: 100%;

        &::before {
            position: relative;
            left: -3px;
        }
    }

    &.js-mobile-navigation__back-button--active {
        justify-content: space-between;

        .icon-arrow-left {
            display: block;
        }
    }
}

.navigation__list-container {
    height: 0;

    overflow: hidden;

    transform: translateX(100%);

    transition: transform $animation-speed ease $animation-speed / 2;

    //transition-delay: $animation-speed / 2;
    //transition-duration: $animation-speed;

    .row {
        height: 100%;

        .col-12 {
            height: 100%;
        }
    }
}

.navigation__list {
    margin-bottom: 0;
    padding: 0;

    list-style: none;
    background-color: $color-white;
}

.navigation__list-slide {
    position: absolute;
    top: -10000px;

    width: 100%;
    height: 100%;

    transform: translateX(100%);

    transition: transform $animation-speed;

    &--sub-navigation {
        .navigation__list-item--link {
            padding-left: 20px;
        }

        .navigation__list-item--main {
            padding-left: 30px;

            font-weight: $font-weight-medium;
        }
    }
}

.navigation__list-item {
    display: flex;
    align-items: center;

    min-height: 50px;

    padding-top: 10px;
    padding-bottom: 10px;

    border-bottom: 1px solid $color-secondary-300;

    font-size: 18px;
    line-height: normal;

    &:last-child {
        border-bottom: 0;
    }
}

.navigation__list-item--link {
    display: block;
    width: 100%;

    color: $color-secondary-300;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    .container {
        display: flex;
        align-items: center;
    }

    .icon-arrow-right {
        display: inline-block;
        height: 20px;
        margin-left: auto;

        font-size: 14px;
        line-height: 24px;
    }
}

.navigation__list-item--active {
    background-color: $color-secondary-800;

    .navigation__list-item--link {
        color: $color-black;
    }
}

.navigation__list--separator-top {
    border-top: 3px solid $color-secondary-300;
}

.navigation__list-wrapper {
    position: relative;

    max-height: calc(100% - 58px);

    overflow: hidden;
    overflow-y: auto;

    background-color: $color-white;

    box-shadow: 0 0 10px 0 $color-secondary-400;
}

.js-mobile-navigation__slide {
    &--active {
        position: relative;
        top: 0;

        transform: translateX(0%);
    }

    &--fade-out {
        transform: translateX(-100%);
    }

    &--fade-out-right {
        top: 0;
    }

    &--fade-in {
        transform: translateX(0%);
    }

    &--fading {
        overflow: hidden;
    }
}

.js-mobile-navigation__close-button {
    display: flex;
    justify-content: flex-end;

    width: 100%;

    &:hover {
        cursor: pointer;
    }
}

@include media-breakpoint-down(sm) {
    .navigation__list-item {
        max-width: 100%;
    }

    .navigation__list-container.container {
        max-width: 100%;

        padding: 0;
    }
}

@include media-breakpoint-between(md, xl) {
    .navigation__list-item--link {
        .container {
            padding-right: 20px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .navigation {
        height: auto;

        &--hidden {
            height: 75px;

            .navigation__brand-container {
                transform: translateY(-100%);
            }

            .navigation__list-container {
                height: auto;

                transform: translate(0%, -100px);
            }
        }

        /* stylelint-disable */
        &--is-safari {
            .navigation__brand {
                .navigation__list-item--link {
                    transform: translateY(2px);
                }
            }

            .navigation__list-wrapper {
                .navigation__list-item--link {
                    .container {
                        transform: translateY(3px);
                    }
                }
            }
        }
        /* stylelint-enable */
    }

    .navigation__brand-container {
        height: 98px;

        .navigation__list {
            margin-right: -15px;
        }
    }

    .navigation__brand {
        padding-top: 15px;
        padding-bottom: 15px;

        .navigation__list-item--link {
            transform: translateY(-2px);
        }
    }

    /* stylelint-disable */
    // CSS-Hack für FireFox
    _::-moz-range-track,
    body:last-child .navigation__list-wrapper .navigation__list-item--link .container {
        transform: translateY(-1px);
    }
    /* stylelint-enable */

    .navigation__list-container {
        height: auto;

        transform: translateY(0%);
    }

    .navigation__list {
        display: flex;
        justify-content: space-between;

        background-color: transparent;
    }

    .navigation__list-slide {
        position: relative;
        top: 0;

        transform: translateX(0);

        &--sub-navigation {
            display: none;
        }
    }

    .navigation__list-item {
        width: 100%;
        padding: 0;
        border-bottom: 0;

        text-align: center;

        background-color: $color-primary;

        &:hover,
        &--active {
            background-color: $color-secondary-800;
        }

        &--active .navigation__list-item--link {
            color: $color-black;
        }

        &:hover .navigation__list-item--link {
            color: $color-primary;
        }

        &:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        &:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &.container {
            padding: 0 15px;
        }

        .js-nested-menu--active {
            color: $color-primary;

            background-color: $color-secondary-800;
        }
    }

    .navigation__list-item--link {
        height: 100%;

        color: $color-white;
        font-weight: $font-weight-medium;

        .icon-arrow-right {
            display: none;
        }

        .container {
            justify-content: center;
            height: 100%;
            padding: 0;

            line-height: 50px;
        }
    }

    .navigation__list--header {
        .navigation__list-item {
            width: auto;

            background-color: transparent;

            &:hover,
            &--active {
                background-color: transparent;
            }

            &:hover .navigation__list-item--link {
                color: $color-primary;
            }

            &--active .navigation__list-item--link {
                color: $color-black;
            }
        }

        .navigation__list-item--link {
            color: $color-secondary-600;
        }
    }

    .navigation__list-wrapper {
        margin-top: 25px;
        border-radius: 3px;

        background-color: transparent;
        box-shadow: none;
    }

    .navigation__search {
        font-size: 30px;

        &.container {
            padding-left: 5px;
        }
    }
}
