.headline__wrapper {
    padding: 0 0 5px;

    &--subline .headline {
        font-size: 20px;
        line-height: 30px;
    }
}

.headline {
    margin-bottom: 0;

    font-size: 24px;
    line-height: 34px;

    &--gray {
        color: $color-secondary-600;
    }
}

@include media-breakpoint-up(md) {
    .headline__wrapper {
        padding: 0 0 15px;
    }

    .headline {
        font-size: 30px;
        line-height: 40px;
    }
}

@include media-breakpoint-up(xl) {
    .headline__wrapper {
        padding: 0 0 25px;

        &--subline .headline {
            font-size: 24px;
        }
    }

    .headline {
        font-size: 42px;
        line-height: 52px;
    }
}
