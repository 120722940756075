.downloads {
    .btn__wrapper,
    .btn {
        width: 100%;
    }
}

.downloads__container {
    padding-top: 25px;
    padding-bottom: 10px;

    &--gray {
        background-color: $color-secondary;

        .downloads__table-td {
            background-color: $color-white;
        }
    }

    .text-combination {
        padding-top: 0;
        padding-bottom: 15px;
    }
}

.downloads__table {
    width: 100%;
    
    color: $color-secondary-300;
}

.downloads__table-head {
    display: none;
}

.downloads__table-tr {
    &--hidden {
        display: none;
    }
}

.downloads__table-th {
    font-weight: $font-weight-medium;
}

.downloads__table-td {
    display: block;

    padding-right: 20px;
    padding-bottom: 5px;
    padding-left: 20px;

    line-height: 25px;

    background-color: $color-secondary;

    &:first-child {
        padding-top: 15px;
    }

    &:last-child {
        margin-bottom: 20px;
        padding-top: 10px;
        padding-bottom: 15px;
    }
}

.downloads__table-td[data-label] {
    display: flex;

    &::before {
        display: block;

        width: 50%;

        font-weight: $font-weight-medium;

        content: attr(data-label);
    }
}

.downloads__table-tr:last-child .downloads__table-td:last-child {
    margin-bottom: 15px;
}

.downloads__link {
    display: block;

    width: 100%;

    text-decoration: none;

    cursor: auto;

    pointer-events: none;

    &:hover {
        text-decoration: none;
    }
}

.downloads__label-close {
    display: none;
}

.downloads__btn-more {
    display: none;
    margin-bottom: 20px;

    &--visible {
        display: block;
    }

    &--show-closed {
        .downloads__label-open {
            display: none;
        }

        .downloads__label-close {
            display: inline-block;
        }
    }
}

@include media-breakpoint-up(md) {
    .downloads__container {
        padding-top: 55px;
        padding-bottom: 50px;

        .text-combination {
            padding-bottom: 20px;
        }
    }

    .downloads {
        .btn__wrapper {
            display: block;

            width: auto;
        }

        .icon-download {
            font-size: 25px;
        }
    }

    .downloads__table {
        border-collapse: separate;
        border-spacing: 0 10px;
    }

    .downloads__table-head {
        display: table-header-group;
    }

    .downloads__table-th {
        display: table-cell;

        line-height: 30px;

        text-align: left;

        &.downloads__filename {
            width: 100%;
        }

        &.downloads__filesize {
            padding-right: 20px;
        }

        &.downloads__date {
            padding-right: 60px;
        }
    }

    .downloads__table-td {
        display: table-cell;

        padding: 0;

        transition: background-color $animation-speed;

        &:first-child {
            padding: 0;
            padding-left: 10px;
        }

        &:last-child {
            display: table-cell;

            margin-bottom: 0;
            padding: 0;
        }

        .btn {
            width: 40px;
            height: 40px;

            padding: 7px 8px 7px 6px;
        }
    }

    .downloads__table-td[data-label] {
        display: table-cell;

        &::before {
            display: none;
        }
    }

    .downloads__table-tr {
        &:hover {
            .downloads__table-td {
                background-color: $color-secondary-800;
            }

            .downloads__link {
                color: $color-primary;
            }

            .btn {
                color: $color-primary;

                background-color: $color-secondary-800;
            }

            .icon-download {
                font-weight: bold;
            }
        }

        &:last-child .downloads__table-td:last-child {
            margin-bottom: 0;
        }
    }

    .downloads__filesize,
    .downloads__date {
        .downloads__link {
            white-space: nowrap;
        }
    }

    .downloads__filename {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .downloads__date {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .downloads__filename,
    .downloads__filesize,
    .downloads__date {
        .downloads__link {
            padding-right: 20px;
        }
    }

    .downloads__btn-more {
        margin: 10px 0;

        .btn {
            width: auto;
        }
    }

    .downloads__link {
        padding-top: 2px;

        cursor: pointer;

        pointer-events: auto;
    }
}

@include media-breakpoint-up(xl) {
    .downloads__container {
        padding-top: 90px;
        padding-bottom: 80px;

        .text-combination {
            padding-bottom: 30px;
        }

        .subline__wrapper {
            padding-bottom: 30px;
        }
    }

    .downloads {
        font-size: 18px;
    }

    .downloads__table {
        margin-bottom: 5px;

        border-spacing: 0 15px;
    }

    .downloads__table-tr:hover {
        .downloads__button {
            background-color: transparent;
        }
    }

    .downloads__table-th {
        &.downloads__filesize {
            padding-right: 140px;
        }

        &.downloads__date {
            padding-right: 145px;
        }
    }

    .downloads__table-td {
        &.downloads__button {
            background-color: transparent;

            .btn__wrapper {
                margin-left: 1px;
            }
        }
    }

    .downloads__btn-more {
        margin: 10px 0 20px;

        .btn {
            width: auto;
        }
    }
}
