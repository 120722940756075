.link-list {
    margin-bottom: 0;
    padding-left: 0;
}

.link-list .link-list__item {
    margin-top: 1px;

    color: $color-secondary-200;

    font-weight: $font-weight-light;
    font-size: 18px;
    line-height: 26px;

    text-align: left;

    list-style: none;
    background-color: $color-secondary;

    a {
        display: block;

        padding: 17px 20px 17px 35px;

        text-decoration: none;
    }
}
