/* stylelint-disable */
#tarteaucitronRoot {
    &.tarteaucitron--show-overlay {
        position: fixed;
        z-index: 10000;

        height: 100%;

        background-color: rgba(0, 0, 0, 0.5);
    }

    button#tarteaucitronBack {
        background-color: rgba(0, 0, 0, 0.5);
    }

    #tarteaucitronAlertBig.tarteaucitronAlertBigBottom {
        padding: 25px 0;

        background-color: white;

        #tarteaucitronDisclaimerAlert {
            margin-bottom: 25px;
        }

        #tarteaucitronDisclaimerAlert,
        .tarteaucitronAlertBigBtnWrapper {
            width: 100%;
        }

        .tarteaucitronAlertBigBtnWrapper {
            display: flex;
            align-items: flex-end;
        }

        #tarteaucitronDisclaimerAlert,
        #tarteaucitronDisclaimerAlert strong,
        #tarteaucitronCloseAlert,
        #tarteaucitronPrivacyUrl {
            color: #444;
        }

        #tarteaucitronPersonalize,
        #tarteaucitronCloseAlert,
        #tarteaucitronPrivacyUrl {
            width: calc(100% - 20px);
            min-height: 36px;
            margin-bottom: 12px;
            margin-left: 0;
            padding: 5px 10px;

            font-size: 16px;

            background-color: #e9e9e9;
        }

        #tarteaucitronPersonalize {
            background-color: #c9081a;
        }

        #tarteaucitronPrivacyUrl {
            margin-bottom: 0;
        }
    }

    #tarteaucitron {
        #tarteaucitronServices {
            #tarteaucitronAllDenied.tarteaucitronIsSelected,
            .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
                background-color: #c9081a;
            }

            .tarteaucitronLine.tarteaucitronIsDenied {
                border-color: #c9081a;
            }
        }
    }

    #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
        background-color: #c9081a;
    }

    .tarteaucitronAlertBigWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 100%;
        margin: 0 auto;
        padding: 0 25px;
    }

    .tarteaucitronAlertBigBtnWrapper {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 576px) {
    #tarteaucitronRoot {
        .tarteaucitronAlertBigWrapper {
            max-width: 540px;
        }
    }
}

@media (min-width: 768px) {
    #tarteaucitronRoot {
        .tarteaucitronAlertBigWrapper {
            flex-direction: row;
            max-width: 720px;
        }

        #tarteaucitronAlertBig.tarteaucitronAlertBigBottom {
            #tarteaucitronDisclaimerAlert {
                margin-bottom: 0;
            }

            #tarteaucitronPersonalize,
            #tarteaucitronCloseAlert,
            #tarteaucitronPrivacyUrl {
                width: 70%;
            }
        }
    }
}

@media (min-width: 992px) {
    #tarteaucitronRoot {
        .tarteaucitronAlertBigWrapper {
            max-width: 960px;
        }

        #tarteaucitronAlertBig.tarteaucitronAlertBigBottom {
            #tarteaucitronPersonalize,
            #tarteaucitronCloseAlert,
            #tarteaucitronPrivacyUrl {
                width: 60%;
            }
        }
    }
}

@media (min-width: 1200px) {
    #tarteaucitronRoot .tarteaucitronAlertBigWrapper {
        max-width: 1140px;
    }
}
/* stylelint-enable */
