ul,
ol {
    font-size: 16px;
    font-family: $font-primary-stack;
    line-height: 27px;
}

@include media-breakpoint-up(xl) {
    ul,
    ol {
        font-size: 18px;
        line-height: 24px;
    }
}
