.btn__wrapper {
    position: relative;

    display: inline-block;
    overflow: hidden;
}

.btn {
    padding: 11px 30px;
    border-radius: 3px;

    color: $color-white;
    font-size: 20px;
    line-height: 26px;
    text-decoration: none;

    background-color: $color-primary;

    &:focus,
    &.focus {
        box-shadow: none;
    }
}

.btn__back {
    width: 100%;

    overflow: initial;

    .btn {
        width: 100%;
    }
}

.btn:hover {
    color: $color-primary;

    background-color: $color-secondary-800;
}

.btn__wrapper--hover {
    .btn {
        color: $color-primary;

        background-color: $color-secondary-800;
    }
}

.btn__wrapper--active {
    .btn {
        color: $color-black;

        background-color: $color-secondary-800;
    }
}

.btn__wrapper--disabled {
    .btn {
        color: $color-secondary-600;

        background-color: $color-secondary-800;
    }
}

.btn__wrapper:not(.btn__wrapper--disabled):hover {
    .btn__wrapper--active {
        .btn {
            color: $color-primary;

            background-color: $color-secondary-800;
        }
    }
}

@include media-breakpoint-up(md) {
    .btn__back {
        width: auto;
    }
}
