.lineclamp {
    position: relative;

    /* stylelint-disable */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* stylelint-enable */

    overflow: hidden;
    -webkit-line-clamp: 1;

    word-break: break-word;

    &--2 {
        -webkit-line-clamp: 2;
    }

    &--3 {
        -webkit-line-clamp: 3;
    }
}

.lineclamp__breakpoint-up--md {
    display: block;
}

@include media-breakpoint-up(md) {
    .lineclamp__breakpoint-up--md {
        /* stylelint-disable */
        display: -webkit-box;
        /* stylelint-enable */
    }
}

@include media-breakpoint-up(xl) {
    .lineclamp--xl-1 {
        -webkit-line-clamp: 1;
    }

    .lineclamp--xl-2 {
        -webkit-line-clamp: 2;
    }

    .lineclamp--xl-3 {
        -webkit-line-clamp: 3;
    }
}

@media screen and (min-width: 1450px) {
    .lineclamp--1450-3 {
        -webkit-line-clamp: 3;
    }
}
