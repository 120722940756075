.splide__arrows {
    display: none;
}

.splide__pagination {
    position: relative;
    bottom: 0;
    left: 0;

    display: flex;
    max-width: 100%;
    margin: 0 auto;
    padding-right: 30px;
    padding-left: 30px;

    transform: translateX(0);

    li {
        margin-right: 5px;

        line-height: 8px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.splide__pagination__page {
    width: 10px;
    height: 10px;

    margin: 0;

    border: 1px solid $color-secondary-600;
    border-radius: 50%;

    background-color: $color-secondary;

    opacity: 1;

    &:hover {
        opacity: 1;
    }

    &.is-active {
        background-color: $color-secondary-600;

        transform: scale(1);
    }

    &--hidden {
        display: none;
    }
}

@include media-breakpoint-up(sm) {
    .splide__pagination {
        max-width: map-get($container-max-widths, sm);
    }
}

@include media-breakpoint-up(md) {
    .splide__arrows {
        display: block;
    }

    .splide__arrow {
        background-color: transparent;

        &--prev {
            left: 30px;
        }

        &--next {
            right: 30px;
        }

        .icon-arrow-left,
        .icon-arrow-right {
            color: $color-secondary-600;

            font-size: 52px;
        }
    }

    .splide__slide {
        margin-right: 20px;
    }

    .splide__pagination {
        left: calc(20px / 2);

        max-width: map-get($container-max-widths, md);
    }
}

@include media-breakpoint-up(lg) {
    .splide__pagination {
        max-width: map-get($container-max-widths, lg);
    }
}

@include media-breakpoint-up(xl) {
    .splide__pagination {
        max-width: map-get($container-max-widths, xl);
    }
}
