.link-icon {
    .icon {
        font-size: 35px;
    }

    .link-icon__team {
        font-size: 24px;
    }
}

.link-icon__link {
    display: inline-flex;
    align-items: center;
    padding-bottom: 5px;

    font-size: 18px;
    text-decoration: none;

    &--no-underline {
        .link-icon__link-content {
            text-decoration: none;
        }
    }

    &:hover {
        text-decoration: none;
    }

    &::before {
        display: inline-block;

        font-size: 35px;
        text-decoration: none;
        vertical-align: middle;
    }
}

.link-icon__link-content {
    display: inline-block;
    margin-left: 10px;

    font-weight: $font-weight-light;
    font-family: $font-primary-stack !important;
    text-decoration: underline;
    vertical-align: middle;
}

@include media-breakpoint-up(md) {
    .link-icon__link {
        font-size: 16px;

        &::before {
            font-size: 20px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .link-icon__link {
        padding-bottom: 10px;

        font-size: 18px;

        &::before {
            font-size: 25px;
        }
    }
}
