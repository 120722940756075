html {
    scroll-behavior: smooth;
}

body {
    font-weight: $font-weight-light;
    font-family: $font-primary-stack;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: $font-weight-light;
}
