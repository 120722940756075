.yt-video {
    position: relative;

    width: 100%;
    height: 0;
    padding-top: 0;
    padding-bottom: 9 / 16 * 100%;
    overflow: hidden;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }
}
