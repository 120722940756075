@font-face {
    font-weight: normal;
    font-family: "proservice-iconfont";
    font-style: normal;
    src: url("#{$font-path}proservice-icons/proservice-iconfont.eot?k4gtsx");
    src: url("#{$font-path}proservice-icons/proservice-iconfont.eot?k4gtsx#iefix") format("embedded-opentype"),
        url("#{$font-path}proservice-icons/proservice-iconfont.ttf?k4gtsx") format("truetype"),
        url("#{$font-path}proservice-icons/proservice-iconfont.woff?k4gtsx") format("woff"),
        url("#{$font-path}proservice-icons/proservice-iconfont.svg?k4gtsx#proservice-iconfont") format("svg");
    font-display: block;
}

%icon {
    font-weight: normal;
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "proservice-iconfont" !important; /* stylelint-disable-line */
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    speak: none;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
    @extend %icon;
}

.icon-arrow-start::before {
    content: "\e900";
}

.icon-arrow-left::before {
    content: "\e901";
}

.icon-arrow-down::before {
    content: "\e902";
}

.icon-arrow-up::before {
    content: "\e903";
}

.icon-arrow-right::before {
    content: "\e904";
}

.icon-arrow-end::before {
    content: "\e905";
}

.icon-badge-mail::before {
    content: "\e906";
}

.icon-badge-phone::before {
    content: "\e907";
}

.icon-badge-schadensmeldung::before {
    content: "\e908";
}

.icon-kontakt-mail::before {
    content: "\e909";
}

.icon-kontakt-mobile::before {
    content: "\e90a";
}

.icon-kontakt-phone::before {
    content: "\e90b";
}

.icon-checkbox-checked::before {
    content: "\e90c";
}

.icon-close::before {
    content: "\e90d";
}

.icon-search::before {
    content: "\e90e";
}

.icon-menue::before {
    content: "\e90f";
}

$icon-plus: "\e910";

.icon-plus::before {
    content: $icon-plus;
}

$icon-minus: "\e911";

.icon-minus::before {
    content: $icon-minus;
}

.icon-checkbox-default::before {
    content: "\e912";
}

.icon-pager-active::before {
    content: "\e913";
}

.icon-pager-default::before {
    content: "\e914";
}

.icon-text-area::before {
    content: "\e915";
}

.icon-download::before {
    content: "\e916";
}

.icon-kontakt-fax::before {
    content: "\e917";
}
