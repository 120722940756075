.carousel-indicators li {
    width: 8px;
    height: 8px;

    border: 1px solid $color-secondary-600;

    background-color: $color-secondary;

    opacity: 1;

    transition: background-color $animation-speed;

    &.active { /* stylelint-disable-line */
        background-color: $color-secondary-600;
    }
}
