.team {
    .text-combination {
        padding: 25px 0;
    }
}

.team__teaser {
    background-color: $color-secondary-900;
}

@include media-breakpoint-up(md) {
    .team {
        .text-combination {
            padding: 40px 0;
        }
    }
}

@include media-breakpoint-up(xl) {
    .team {
        .text-combination {
            padding: 95px 0 65px;
        }
    }

    .team__subline {
        padding-top: 35px;
    }

    .team__copy {
        padding-top: 35px;
    }
}
