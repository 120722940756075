.insurances {
    padding-bottom: 20px;

    .text-combination {
        padding: 25px 0 15px;
    }

    .teaser__container {
        padding: 0;
    }
}

.insurances__headline {
    margin-bottom: 5px;
}

@include media-breakpoint-up(md) {
    .insurances {
        padding-bottom: 60px;

        .text-combination {
            padding: 55px 0 35px;
        }
    }

    .insurances__headline {
        margin-bottom: 15px;
    }
}

@include media-breakpoint-up(xl) {
    .insurances {
        padding-bottom: 100px;

        .text-combination {
            padding: 90px 0 50px;
        }
    }

    .text-combination.insurance__text-combination {
        .headline__wrapper + .text-combination__copy-row {
            margin-top: 30px;
        }
    }

    .insurances__headline {
        margin-bottom: 30px;
    }
}
