.separator {
    padding-top: 50px;
    padding-bottom: 50px;

    text-align: center;

    &--has-hover:hover {
        cursor: pointer;
    }
}

.separator__line {
    height: 2px;
    margin: 20px 0;

    background-color: $color-secondary-600;
}

.separator__btn {
    position: relative;
    top: -25px;

    .icon-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        padding: 0;
    }
}

@include media-breakpoint-up(md) {
    .separator__line {
        margin: 0;
    }
}
