.controller-news {
    .expander__holder--active + .teaser__container {
        margin-top: 45px;
    }
}

.controller-news__copy {
    .text-image-combination__image-container {
        margin-bottom: 14px;
    }
}

.controller-news__head-image {
    width: 100%;
}

.controller-news-detail {
    .btn__back {
        margin-top: 20px;
    }
}

.controller-news-detail__container {
    .text-combination {
        padding-top: 20px;
    }
}

@include media-breakpoint-up(md) {
    .controller-news {
        padding-top: 55px;
        padding-bottom: 60px;

        .teaser__container {
            padding-top: 25px;
            padding-bottom: 0;

            .teaser__column:nth-of-type(n+4) {
                padding-top: 20px;
            }
        }

        .teaser__wrapper {
            padding: 0;
        }
    }

    .controller-news__copy {
        .text-image-combination__image-container {
            margin-bottom: 0;
        }

        .copy.text-image-combination__image-container {
            padding-left: 20px;
        }

        .order-last.text-image-combination__image-container + .copy {
            padding-right: 20px;
            padding-left: 0;
        }
    }

    .controller-news__text-combination {
        padding: 0;
    }

    .controller-news-detail {
        .btn__back {
            margin-top: 30px;
        }
    }

    .controller-news-detail__container {
        .text-combination {
            padding-top: 45px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .controller-news {
        padding-top: 0;
        padding-bottom: 100px;

        .teaser__container {
            padding-top: 0;

            .teaser__column:nth-of-type(n+4) {
                padding-top: 30px;
            }
        }
    }

    .controller-news__copy {
        .copy.text-image-combination__image-container {
            padding-left: 30px;
        }

        .order-last.text-image-combination__image-container + .copy {
            padding-right: 30px;
            padding-left: 0;
        }
    }

    .controller-news-detail {
        padding-bottom: 0;
    }

    .controller-news-detail__container {
        .text-combination {
            padding-top: 65px;
            padding-bottom: 80px;

            .controller-news-detail__subline {
                padding: 18px 0 0;
            }
        }

        .subline__wrapper {
            padding-bottom: 15px;
        }
    }
}
