.news-teaser {
    padding-bottom: 20px;

    .teaser__wrapper {
        padding-bottom: 15px;

        .teaser {
            background-color: $color-white;
        }
    }
}

.splide__arrows {
    .splide__arrow {
        .icon-arrow-left,
        .icon-arrow-right {
            transition: all ease-in-out $animation-speed;
        }

        .icon-arrow-left:hover,
        .icon-arrow-right:hover {
            color: $color-primary-300;
            text-decoration: none;

            outline: 0;
            opacity: 0.9;
        }
    }
}

.news-teaser__container {
    padding-top: 25px;
    padding-bottom: 30px;
}

.news-teaser__date {
    margin-bottom: 5px;

    color: $color-secondary-300;
}

@include media-breakpoint-up(md) {
    .news-teaser .teaser__wrapper {
        padding-bottom: 20px;
    }

    .news-teaser__container {
        padding: 50px 0 60px;
    }
}

@include media-breakpoint-up(xl) {
    .news-teaser {
        padding-bottom: -10px;

        .teaser__wrapper {
            padding-bottom: 30px;
        }
    }

    .news-teaser__container {
        padding: 90px 0 100px;
    }

    .news-teaser__date {
        font-size: 18px;
    }

    .subline.teaser__subline {
        padding: 0;
    }
}
