// Farben werden überschrieben
$primary: $color-primary !default;
$secondary: $color-secondary !default;
$light: $color-white !default;
$dark: $color-black !default;

$font-family-base: $font-primary-stack !default;

$container-max-widths: (
    sm: 570px,
    md: 750px,
    lg: 990px,
    xl: 1660px
) !default;
