.head-slider {
    position: relative;

    overflow: hidden;

    .carousel-inner {
        overflow: visible;
    }

    &__image-ratio--30-9 {
        .head-slider__item-image {
            height: calc((100vw - 17px) * 9 / 30);
        }

        .head-slider__dots {
            top: calc((100vw * 9 / 30) - 18px - 10px);
        }
    }
}

.head-slider__item {
    z-index: 3;

    width: 100%;

    .head-slider__item-text {
        z-index: 16;
    }
}

.head-slider__item-image {
    height: calc((100vw - 17px) * 9 / 21);

    color: $color-white;

    background-color: $color-primary;

    img {
        width: 100%;
        max-height: 100%;
    }
}

.head-slider__item-image-data {
    margin-top: 8px;
}

.head-slider__item-text {
    border-left: 10px solid $color-primary;

    background-color: $color-secondary;

    .teaser {
        padding: 20px 0;
    }

    .teaser__headline {
        margin-bottom: 0;

        color: $color-primary;
        font-weight: $font-weight-medium;
        font-size: 20px;

        + .teaser__btn {
            margin-top: 20px;
        }

        &.subline,
        &.subline p {
            color: $color-secondary-300;
            font-weight: $font-weight-light;
            font-size: 16px;
        }

        p {
            margin-bottom: 0;

            color: $color-primary;
            font-weight: $font-weight-medium;
            font-size: 20px;
        }
    }

    .teaser__copy {
        margin-bottom: 0;

        + .teaser__btn {
            margin-top: 20px;
        }
    }

    .teaser__btn {
        width: 100%;

        overflow: initial;

        text-align: center;
    }

    .btn {
        width: 100%;
    }
}

.head-slider__dots {
    top: calc((100vw * 9 / 21) - 18px - 10px);
    bottom: auto;

    margin: 0;
}

.head-slider__control {
    bottom: auto;
    z-index: 5;

    display: none;
    width: 50px;

    color: $color-white;

    font-size: 28px;

    opacity: 1;

    transition: color $animation-speed;

    &:hover {
        color: $color-primary;
    }
}

.head-slider__dot {
    border-radius: 50%;
}

.head-slider__scroll {
    background-color: $color-white;
}

.head-slider__scroll-hint {
    display: none;
    padding-top: 25px;

    color: $color-secondary-300;
    text-align: center;
}

@include media-breakpoint-up(md) {
    .head-slider__image-ratio--30-9 {
        .head-slider__dots {
            top: calc(100vw * 9 / 30 - 18px - 15px);
        }

        .head-slider__control {
            top: calc(100vw * 9 / 30 - 52px - 15px);
        }

        .head-slider__item-text {
            .teaser {
                max-height: calc(100vw * 9 / 30 - 100px);
            }
        }
    }

    .head-slider__item {
        z-index: 3;

        transition: $animation-speed * 3 transform;
        transition-delay: $animation-speed / 2;

        &.has-text {
            .head-slider__item-image {
                .container {
                    padding-top: 50px;
                }
            }
        }

        .head-slider__item-text {
            z-index: 16;

            background-color: transparent;

            opacity: 0;

            transition: opacity $animation-speed * 1.5;
        }

        .head-slider__item-image .container {
            transition: opacity $animation-speed * 1.5;
        }

        &.active {
            .head-slider__item-text,
            .head-slider__item-image .container {
                opacity: 1;
            }
        }

        &.carousel-item-left,
        &.carousel-item-right {
            .head-slider__item-text,
            .head-slider__item-image .container {
                opacity: 0;
            }
        }

        &.carousel-item-next,
        &.carousel-item-prev {
            z-index: 1;

            transition: $animation-speed * 3 transform;
        }
    }

    .head-slider__item-image {
        color: $color-primary;

        background-color: $color-white;
    }

    .head-slider__item-text {
        position: absolute;
        top: 50px;

        width: 100%;
        border-left: 0;

        background-color: transparent;

        .teaser {
            position: relative;
            z-index: 20;

            width: 50%;
            max-height: calc(100vw * 9 / 21 - 100px);

            padding: 20px 25px 25px 15px;

            border-left: 10px solid $color-primary;

            overflow: auto;

            background-color: rgba(255, 255, 255, 0.8);
        }

        .teaser__btn,
        .btn {
            width: auto;
        }
    }

    .head-slider__dots {
        top: calc(100vw * 9 / 21 - 18px - 15px);
    }

    .head-slider__control {
        top: calc(100vw * 9 / 21 - 52px - 15px);

        display: flex;

        text-decoration: none;

        .icon-arrow-left,
        .icon-arrow-right {
            font-size: 52px;
            text-shadow: 0 0 2px rgba(0, 0, 0, 1);
        }

        .icon-arrow-left {
            padding-left: 30px;
        }

        .icon-arrow-right {
            padding-right: 30px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .head-slider__image-ratio--30-9 {
        .head-slider__control {
            top: calc(100vw * 9 / 30 / 2 - 25px);
        }

        .head-slider__dots {
            top: calc(100vw * 9 / 30 - 18px - 18px);
        }

        .head-slider__item-text {
            .teaser {
                max-height: calc(100vw * 9 / 30 - 260px);
            }
        }
    }

    .head-slider__item-text {
        top: 130px;

        .teaser {
            width: 34.375%;
            max-height: calc(100vw * 9 / 21 - 260px);
            padding: 25px 30px 30px 20px;
        }

        .teaser__headline {
            font-size: 24px;

            &.subline {
                font-size: 18px;
            }

            + .teaser__headline {
                margin-top: 5px;
            }

            + .teaser__copy {
                margin-top: 5px;
            }

            + .teaser__btn {
                margin-top: 25px;
            }
        }

        .teaser__copy + .teaser__btn {
            margin-top: 25px;
        }
    }

    .head-slider__item-image-data {
        .image__subline,
        .image__copyright {
            width: 34.375%;
        }
    }

    .head-slider__control {
        top: calc(100vw * 9 / 21 / 2 - 25px);
    }

    .head-slider__dots {
        top: calc(100vw * 9 / 21 - 18px - 20px);
    }

    .head-slider__scroll-hint {
        display: block;
    }
}
