.insurance {
    padding-bottom: 20px;

    .teaser .icon {
        font-size: 22px;
    }

    .link-icon__link {
        padding-bottom: 5px;

        font-size: 16px;
    }
}

@include media-breakpoint-up(md) {
    .insurance {
        padding-bottom: 60px;
    }

    .insurance__text-combination,
    .insurance__contact {
        padding-top: 55px;
        padding-bottom: 40px;
    }
}

@include media-breakpoint-up(xl) {
    .insurance {
        padding-bottom: 100px;
    }

    .insurance__text-combination {
        padding-top: 100px;
        padding-bottom: 90px;
    }

    .insurance__downloads {
        padding-bottom: 60px;
    }

    .insurance__contact {
        padding-top: 90px;
        padding-bottom: 65px;
    }
}
